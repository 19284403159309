<template>
  <div>
    <div class="div-title-video">
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <router-link class="active router-menu-video btn mb-3" :to="'/modulos/' + id_curso"><i
            class="bi bi-chevron-double-left"></i> Voltar</router-link>

        <button class="mb-3 btn btn-outline-light btn-assistido" :style="backgroudChcek" @click="checkVideo"><i
            class="bi bi-check2-circle"></i> Concluído</button>
      </div>
      <h2 class="title">{{ getTituloVideo }}</h2>
      <h6 class="breadcrumb">{{ nomeCurso }} -> {{ titulo_modulo }}</h6>
    </div>
    <div class="anexos" v-for="(anexo, index) in getAnexos" :key="index">
      <a :href="linkAnexo + anexo.ds_arquivo" target="_blank">{{ anexo.ds_arquivo }}</a>
    </div>
    <div class="video">
      <iframe class="single-video" width="820" height="515" :src='getLinkVideo'>
      </iframe>

      <div class="description-video">
        <p>{{ getDescVideo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titulo: '',
      nomeCurso: '',
      titulo_modulo: '',
      id_curso: '',
      anexos: []
    }
  },

  created() {
    this.$store.dispatch('modulos/getModulo', { nr_seq_modulo: this.$route.params.id }).then(response => {
      this.titulo_modulo = response.data[0].titulo_modulo
      this.$store.dispatch('modulos/getCursosFromModule', { nr_seq_curso: response.data[0].nr_seq_curso }).then(response => {
        this.nomeCurso = response.data.curso.titulo_curso
        this.id_curso = response.data.curso.nr_sequencial
      })
    })
  },

  computed: {
    linkAnexo() {
      const host = window.location.hostname

      if (host == 'localhost') {
        return 'http://localhost:8000/files/'
      } else if (host == 'gclass.inovess.com.br') {
        return 'https://apigclass.inovess.com.br/files/'
      }
    },
    getInformationVideo() {
      const video = this.$store.getters['videos/singleVideo']

      return video
    },
    getAnexos() {
      const anexos = this.getInformationVideo;
      console.log(anexos)
      this.anexos = anexos.anexos

      return anexos.anexos
    },
    getTituloVideo() {
      const titulo = this.getInformationVideo

      return titulo.titulo_video
    },

    getLinkVideo() {
      const link = this.getInformationVideo

      return link.link_video
    },

    getDescVideo() {
      const desc = this.getInformationVideo

      return desc.ds_video
    },
    backgroudChcek(){
      const check = this.getInformationVideo

      if(check.checkVideo?.nr_sequencial) {
        return 'background: rgb(0 139 0);';
      }

      return '';
    }
  },

  methods: {
    setTitulo(titulo) {
      this.titulo = titulo
    },
    checkVideo(){
      console.log('Video Assistido')
      const data = {
        nr_seq_video: this.getInformationVideo.nr_sequencial
      }

      this.$store.dispatch('videos/checkVideo', { data: data })
      document.querySelector('.btn-assistido').style.background = 'rgb(0 139 0)'
    }
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />