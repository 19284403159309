<script>
  export default {
    data() {
      return {
        autorizado: false,
      };
    },
    created() {
      const auth = this.$store.getters['auth/getAuth']
      if(auth.token){
        this.autorizado = true
      }
    },
    methods: {
      logout(){
        this.$store.dispatch('auth/logout')
        window.location.reload()
      }
    }
  }
</script>
<template>
  <div>
    <nav class="row nav-menu">
      <div class="logo col-2">
        <img class="img-logo" src="@/assets/images/logo_branca.png" alt="" />
      </div>
      <div class="links-menu col-10">
        <a href="/">Início</a>
        <a href="/#categorias">Sobre</a>
        <a href="/#cursos">Cursos</a>
        <a href="/#novidades">Novidades</a>
        <a href="/#contato">Contato</a>
        <a v-if="!autorizado" href="/entrar">Entrar</a>
        <button class="btn btn-outline-light" v-if="autorizado" @click="logout">Deslogar</button>
        <button class="btn">
          <i class="bi bi-search"></i>
        </button>
      </div>
    </nav>
  </div>
</template>
<style lang="scss" src="./style.scss" scoped>