import api from '@/common/api.js'

export default {
  namespaced: true,

  state: {
    auth: null
  },
  getters: {
    getAuth: (state) => state.auth
  },
  actions: {
    async login({ commit }, { data }) {
        try {
            const response = await api.post('auth/login', data) 
            console.log(response)
            const dataResponse = {
                token: response.data.token,
                user: response.data.user
            }

            commit('setLocalStorage', dataResponse)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    loadFromLocalStorage: ({ commit, state }) => {
        if(localStorage.auth){
            const auth = JSON.parse(localStorage.auth)
        
            commit('setLocalStorage', auth)
        }
    },
    logout({ commit }) {
        // Limpa o token e os dados do usuário no Vuex e localStorage
        commit('setToken', null);
        localStorage.removeItem('auth');
    }
  },
  mutations: {
    setLocalStorage(state, data){
        const local =  JSON.stringify(data)
        localStorage.setItem('auth', local);
        state.auth = data
    },
    setToken(state, data) {
        state.auth = data
    }
  },
}
