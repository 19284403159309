<template>
    <div class="login-wrapper">
        <div class="login-container">
            <h1>Login</h1>
            <!-- <h6>Entre com seu login e tenha acesso aos cursos!</h6> -->
            <form @submit.prevent="handleLogin">
                <div class="form-group mt-4">
                    <!-- <label for="login">Login:</label> -->
                    <input type="text" id="login" v-model="login" placeholder="Digite seu login" required />
                </div>

                <div class="form-group mt-4">
                    <!-- <label for="password">Senha:</label> -->
                    <input type="password" id="password" v-model="password" placeholder="Digite sua senha" required />
                </div>
                <div class="div-links">
                    <a href="#">Sou novo aqui</a>
                    <a href="#">Esqueci minha senha</a>
                </div>
                <hr style="color: #fff">
                <button type="submit">Entrar</button>
            </form>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        login: '',
        password: '',
      };
    },
    methods: {
      handleLogin() {
        // Aqui você pode adicionar a lógica de autenticação, como uma chamada para uma API
        console.log('Login:', this.login);
        console.log('Senha:', this.password);
  
        // Exemplo simples de validação
        if (this.login && this.password) {
        
            const data = {
                login: this.login,
                senha: this.password
            }

            this.$store.dispatch('auth/login', { data: data }).then(response => {
                window.location.href = '/'
            })
            .catch((e) => {
                this.$swal.fire({
                    tite: 'Oops!',
                    text: 'Usuário não encontrado!',
                    icon: 'warning'
                })
            })
        } else {
          alert('Por favor, preencha todos os campos.');
        }
      },
    },
  };
  </script>


<style scoped>
    .div-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 10px;
    }

    a {
        text-decoration: none;
        color: #00B37E;
    }

    h1,
    h6 {
        color: #fff;
    }

    /* Fundo escuro cobrindo a tela inteira com imagem e sobreposição */
    .login-wrapper {
        display: flex;
        padding-top: 80px;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;
        background: url('../../assets/images/background_02.jpg') no-repeat center center;
        background-size: cover;
    }

    /* Sobreposição de cor sobre a imagem de fundo */
    .login-wrapper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        /* Cor preta com opacidade de 60% */
        z-index: 1;
    }

    /* Container do login centralizado */
    .login-container {
        max-width: 400px;
        width: 100%;
        padding: 30px;
        background-color: rgba(68, 68, 68, 0.9);
        /* Fundo do card de login com 90% de opacidade */
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        z-index: 2;
        /* Garante que o formulário fique acima da sobreposição */
    }

    .form-group {
        margin-bottom: 15px;
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        width: 100%;
        padding: 14px;
        border: 1px solid #555;
        border-radius: 35px;
        ;
        background-color: #555;
        color: #fff;
        box-sizing: border-box;
    }

    button {
        width: 100%;
        padding: 12px;
        background: linear-gradient(45deg, #015F43, #00875F );
        color: white;
        border: none;
        border-radius: 35px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
    }

    button:hover {
        background-color: #0056b3;
    }

</style>